import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCode, faNetworkWired, faDatabase, faLanguage, faTerminal, faBriefcase, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { faHtml5, faReact, faJs, faPython, faJava, faJira, faBitbucket} from '@fortawesome/free-brands-svg-icons';

function Resume() {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  const [activeSection, setActiveSection] = useState('experience');

  useEffect(() => {
    const handleScroll = (e) => {
      const sections = document.querySelectorAll('section[id]');
      const scrollPosition = window.scrollY + 200;
    
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');
        
        if (
          scrollPosition > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
            setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuItemClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
      setActiveSection(sectionId)
    }
    
  };

  return (
    <div className="flex">
        {/* Lateral Menu */}
        <div className='mt-20 fixed left-0 top-1/4 h-screen w-1/3 text-white overflow-y-auto pb-10'>
            <aside className="h-auto">
                <div className="mb-4 flex justify-center">
                    <h4
                        className={` text-xl mb-6 text-center text-[#54D3D4] cursor-pointer ${
                        activeSection === 'experience' ? 'font-bold text-xl' : ''
                        }`}
                        onClick={() => handleMenuItemClick('experience')}
                    > Work Experience
                    </h4>
                </div>
                <div className="mb-4 flex justify-center">
                    <h4
                        className={` text-xl mb-6 text-center text-[#54D3D4] cursor-pointer ${
                        activeSection === 'education' ? 'font-bold text-xl' : ''
                        }`}
                        onClick={() => handleMenuItemClick('education')}
                    > Education
                    </h4>
                </div>
                <div className="mb-4 flex justify-center">
                    <h4
                        className={` text-xl mb-6 text-center text-[#54D3D4] cursor-pointer ${
                        activeSection === 'skills' ? 'font-bold text-xl' : ''
                        }`}
                        onClick={() => handleMenuItemClick('skills')}
                    > Skills
                    </h4>
                </div>
                <div className='mb-4 flex justify-center'>
                    <h4
                        className={`text-xl mb-6 text-center text-[#54D3D4] cursor-pointer ${
                            activeSection === 'languages' ? 'font-bold' : ''
                        }`}
                        onClick={() => handleMenuItemClick('languages')}
                        >
                        Languages
                    </h4>
                </div>
             </aside>
        </div>
        <main className="mt-20 ml-32 overflow-y-auto w-full flex justify-center"> {/* Adjust margin-left to match the lateral menu width */}
            <div id="about" className="w-full max-w-4xl text-white grid grid-cols-1 gap-60">
            {/* Experience */}
                <section id="experience" className="rounded-lg p-6 text-[#C4D1EC]">
                    <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'experience' ? 'font-bold' : ''}`}>Work Experience <FontAwesomeIcon icon={faBriefcase} className='ml-3'/></h2>
                    <div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.webcargo.co/" target='_blank' rel="noreferrer" className="linkcard" aria-label="WebCargo Team Lead & Software Engineer">
                        <div className='col-span-1'>
                        <h4 className='text-md text-center text-slate-500'>2022 — present</h4>
                            <h4 className='text-md text-center text-slate-300'>Barcelona, Spain</h4>
                        </div>
                        <div className='col-span-4'>
                            <h3 className="text-lg font-bold mb-2 text-white">Team Lead & Software Engineer - Webcargo <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">As a Team Lead & Software Engineer at WebCargo, I lead the development of innovative features while ensuring the integrity and reliability of our application. Overseeing a team of developers, I am responsible for building and maintaining critical components essential to our platform's functionality. 
                                <br/>Additionally, I collaborate closely with cross-functional teams to ensure seamless integration and advocate for best practices in software development.</p><br/>
                            <p className="text-sm">As a Team Lead and Software Engineer at Webcargo, I led several key projects:</p><br/>
                            <ul className="text-sm">
                                <li><b>Scheduling System:</b> Designed and implemented a scheduling system specifically aimed at optimizing repetitive tasks for our clients. This solution streamlined their workflows, reduced manual effort, and improved overall efficiency.</li><br/>
                                <li><b>Third-Party API Integration:</b> Directed the integration of our platform with a third-party service through a complex API. This project involved seamless data exchange, authentication management, and resolving compatibility issues to enhance our application's capabilities.</li><br/>
                                <li><b>New Email System:</b> Spearheaded the creation of a new email system to improve internal and client communications. This included designing the system’s architecture, integrating it with existing tools, and ensuring its reliability and scalability.</li><br/>
                            </ul>
                            <ul className='chiplist'>
                            <li className='col-span-1'><div className='chip'>PHP</div></li>
                            <li className='col-span-1'><div className='chip'>React</div></li>
                            <li className='col-span-1'><div className='chip'>MySQL</div></li>
                            <li className='col-span-1'><div className='chip'>Jira</div></li>
                            <li className='col-span-1'><div className='chip'>Confluence</div></li>
                            </ul>
                        </div>
                        </a>
                    </div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.webcargo.co/" target='_blank' rel="noreferrer" className="linkcard" aria-label="WebCargo Software Engineer">
                        <div className='col-span-1'>
                            <h4 className='text-md text-center text-slate-500'>2019 — 2022</h4>
                            <h4 className='text-md text-center text-slate-300'>Barcelona, Spain</h4>
                        </div>
                        <div className='col-span-4'>
                            <h3 className="text-lg font-bold mb-2 text-white">Senior Software Engineer - Webcargo <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">In my previous role at WebCargo as a Software Engineer, I was instrumental in developing new features while upholding the integrity of the application.
                                <br/> I actively contributed to enhancing the platform's functionality, focusing on improving user experience and driving business success.
                                <br/> Additionally, I ensured the reliability and stability of the application, maintaining high standards of performance and quality.</p><br/>
                            <p className="text-sm">As a Senior Software Engineer at Webcargo, I led several key projects:</p><br/>
                            <ul className="text-sm">
                                <li><b>Payment System:</b> Implemented a comprehensive payment system, incorporating secure transaction processing, user-friendly interfaces, and integration with various payment gateways to facilitate seamless financial operations.</li><br/>
                                <li><b>Search History:</b>  Implemented a search history functionality to enhance user experience by providing personalized search results and allowing users to easily access their previous searches.</li><br/>
                                <li><b>Package System:</b> Introduced a new package management system. This included designing the system for flexibility, scalability, and ease of integration with existing workflows.</li><br/>
                            </ul>
                            <ul className='chiplist'>
                            <li className='col-span-1'><div className='chip'>PHP</div></li>
                            <li className='col-span-1'><div className='chip'>React</div></li>
                            <li className='col-span-1'><div className='chip'>MySQL</div></li>
                            <li className='col-span-1'><div className='chip'>CSS</div></li>
                            <li className='col-span-1'><div className='chip'>APIs</div></li>
                            </ul>
                        </div>
                        </a>
                    </div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.nextlane.com/es/" target='_blank' rel="noreferrer" className="linkcard" aria-label="Nextlane Junior Software Engineer">
                        <div className='col-span-1'>
                            <h4 className='text-md text-center text-slate-500'>2018 — 2019</h4>
                            <h4 className='text-md text-center text-slate-300'>Madrid, Spain</h4>
                        </div>
                        <div className='col-span-4'>
                            <h3 className="text-lg font-bold mb-2 text-white">Junior Software Engineer - Imaweb/Nextlane <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">As a Junior Software Engineer at Imaweb, I played a vital role in the development of new features while ensuring the integrity of our applications.
                                <br/> Working closely with senior engineers, I contributed to the implementation of innovative functionalities aimed at enhancing user experience and driving business objectives. 
                                <br/> Additionally, I assisted in maintaining the reliability and stability of our applications, adhering to best practices and standards in software development.</p>
                            <ul className='chiplist'>
                            <li className='col-span-1'><div className='chip'>PHP</div></li>
                            <li className='col-span-1'><div className='chip'>JQuery</div></li>
                            <li className='col-span-1'><div className='chip'>CSS</div></li>
                            </ul>
                        </div>
                        </a>
                    </div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.colanguage.com/" target='_blank' rel="noreferrer" className="linkcard" aria-label="CoLanguage Intern Software Engineer">
                        <div className='col-span-1'>
                            <h4 className='text-md text-center text-slate-500'>2018 <span className='text-xs'>(Apr)</span> — <span className='text-xs'>(Aug)</span></h4>
                            <h4 className='text-md text-center text-slate-300'>Ghent, Belgium</h4>                        
                        </div>
                        <div className='col-span-4'>
                            <h3 className="text-lg font-bold mb-2 text-white">Intern Software Engineer - CoLanguage <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">As an Intern Software Engineer at CoLanguage, I actively participated in developing new features while prioritizing the integrity of our application. 
                                <br/> Under the guidance of experienced engineers, I contributed to implementing innovative functionalities aimed at improving user experience and supporting company objectives.</p>
                            <ul className='chiplist'>
                            <li className='col-span-1'><div className='chip'>Drupal</div></li>
                            <li className='col-span-1'><div className='chip'>JavaScript</div></li>
                            </ul>
                        </div>
                        </a>
                    </div>
                    </div>
                </section>

                {/* Education */}
                <section id="education" className="rounded-lg p-6 text-[#C4D1EC]">
                    <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'education' ? 'font-bold' : ''}`}>Education <FontAwesomeIcon icon={faUserGraduate} className='ml-3' /></h2>
                    <div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.iesgrancapitan.org/" target='_blank' rel="noreferrer" className="linkcard">
                        <div className='col-span-1'>
                            <h4 className='text-md text-center text-slate-500'>2016 — 2018</h4>
                        </div>
                        <div className='col-span-3'>
                            <h3 className="text-lg font-bold mb-2 text-white">Web Application Development <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">I.E.S Gran Capitan — Córdoba</p>
                        </div>
                        <div className='col-span-1 flex justify-center items-center'>
                            <FontAwesomeIcon icon={faTerminal} size="2xl"/>
                        </div>
                        </a>
                    </div>
                    <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <a href="https://www.iesmedinaazahara.es/" target='_blank' rel="noreferrer" className="linkcard">
                        <div className='col-span-1'>
                            <h4 className='text-md text-center text-slate-500'>2014 — 2016</h4>
                        </div>
                        <div className='col-span-3'>
                            <h3 className="text-lg font-bold mb-2 text-white">Microcomputer Systems and Networks <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                            <p className="text-sm">I.E.S Medina Azahara  — Córdoba</p>
                        </div>
                        <div className='col-span-1 flex justify-center items-center'>
                            <FontAwesomeIcon icon={faNetworkWired} size="2xl" />
                        </div>
                        </a>
                    </div>
                    </div>
                </section>
                
                {/* Skills */}
                <section id="skills" className="rounded-lg p-6 text-[#C4D1EC]">
                    <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'skills' ? 'font-bold' : ''}`}>Skills <FontAwesomeIcon icon={faCode} className='ml-3' /></h2>
                    <div className="grid grid-cols-3 gap-6">
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faCode} size="3x" />
                            <span className="text-md">PHP</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faHtml5} size="3x" />
                            <span className="text-md">HTML/CSS</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faReact} size="3x" />
                            <span className="text-md">React</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faJs} size="3x" />
                            <span className="text-md">JavaScript</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faPython} size="3x" />
                            <span className="text-md">Python</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faDatabase} size="3x" />
                            <span className="text-md">MySQL</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faJava} size="3x" />
                            <span className="text-md">Java</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faJira} size="3x" />
                            <span className="text-md">Jira</span>
                        </div>
                        <div className="skillscard">
                            <FontAwesomeIcon icon={faBitbucket} size="3x" />
                            <span className="text-md">Bitbucket</span>
                        </div>
                    </div>
                </section>

                {/* Languages */}
                <section id="languages" className="rounded-lg p-6 text-[#C4D1EC]">
                <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'languages' ? 'font-bold' : ''}`}>Languages <FontAwesomeIcon icon={faLanguage} className='ml-3' /></h2>
                <div className="grid grid-cols-2 gap-5">
                    <div className="skillscard flex flex-col justify-center items-center">
                        <span className="text-md">Spanish</span>
                        <div className="w-20 h-1 flex mt-1">
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500  rounded-full mx-1"></div>
                        </div>
                    </div>
                    <div className="skillscard flex flex-col justify-center items-center">
                        <span className="text-md">English</span>
                        <div className="w-20 h-1 flex mt-1">
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        </div>
                    </div>
                    <div className="skillscard flex flex-col justify-center items-center">
                        <span className="text-md">Catalan</span>
                        <div className="w-20 h-1 flex mt-1">
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        </div>
                    </div>
                    <div className="skillscard flex flex-col justify-center items-center">
                        <span className="text-md">French</span>
                        <div className="w-20 h-1 flex mt-1">
                        <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                        </div>
                    </div>
                </div>
                </section>

                <section id="hidden" className='h-60'></section>
        </div>
        </main>
    </div>
  );
}

export default Resume;
