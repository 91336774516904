import React from 'react';

function Portfolio() {
  return (
    <div>
      <h2>Portfolio</h2>
      <div className="project">
        <h3>Project 1</h3>
        <p>Description of Project 1</p>
      </div>
      <div className="project">
        <h3>Project 2</h3>
        <p>Description of Project 2</p>
      </div>
      {/* Add more projects as needed */}
    </div>
  );
}

export default Portfolio;
