import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Contact() {
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => alert('Phone number copied to clipboard!'),
            (err) => alert('Failed to copy phone number.')
        );
    };

    const handleLocationClick = () => {
        window.open('https://www.google.com/maps/search/?api=1&query=Cordoba,+España', '_blank');
    };

    return (
        <div className="flex justify-center items-center h-screen bg-[#09192F]">
            <div className="grid grid-cols-2 gap-6 max-w-4xl w-full p-6">
                {/* Email */}
                <div 
                    className="bg-[#1E2A38] text-[#C4D1EC] rounded-lg p-6 flex flex-col items-center hover:bg-[#2E3A4C] transition duration-300 cursor-pointer" 
                    onClick={() => window.location.href = 'mailto:joselucenamorente@outlook.es'}
                >
                    <FontAwesomeIcon icon={faEnvelope} size="2x" className="mb-4" />
                    <h3 className="text-lg font-bold mb-2">Email</h3>
                    <p className="text-center">joselucenamorente@outlook.es</p>
                </div>

                {/* Phone */}
                <div 
                    className="bg-[#1E2A38] text-[#C4D1EC] rounded-lg p-6 flex flex-col items-center hover:bg-[#2E3A4C] transition duration-300 cursor-pointer" 
                    onClick={() => handleCopyToClipboard('+34 685489039')}
                >
                    <FontAwesomeIcon icon={faPhone} size="2x" className="mb-4" />
                    <h3 className="text-lg font-bold mb-2">Phone</h3>
                    <p className="text-center">+34 685 48 90 39</p>
                </div>

                {/* Location */}
                <div 
                    className="bg-[#1E2A38] text-[#C4D1EC] rounded-lg p-6 flex flex-col items-center hover:bg-[#2E3A4C] transition duration-300 cursor-pointer" 
                    onClick={handleLocationClick}
                >
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="mb-4" />
                    <h3 className="text-lg font-bold mb-2">Location</h3>
                    <p className="text-center">Córdoba, España</p>
                </div>

                {/* Social Media */}
                <div className="bg-[#1E2A38] text-[#C4D1EC] rounded-lg p-6 flex flex-col items-center hover:bg-[#2E3A4C] transition duration-300">
                    <h3 className="text-lg font-bold mb-2">Follow Me</h3>
                    <div className="flex space-x-4">
                        <a href="https://www.linkedin.com/in/joselucena/" target='_blank' rel="noreferrer" className="text-[#C4D1EC] hover:text-gray-400">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </a>
                        <a href="https://www.instagram.com/joselucena_rt/" target='_blank' rel="noreferrer" className="text-[#C4D1EC] hover:text-gray-400">
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
