import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons';

function Menu() {

  const location = useLocation();

  return (
    <nav id='main-menu' className="top-0 left-0 w-full p-4 text-white">
      <ul className="flex justify-end container mx-auto py-4">
        <li className="mr-9">
            <NavLink to="/"  className={`text-gray-200 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 ${location.pathname === '/' ? 'bg-gray-700' : ''}`}>
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                Home
            </NavLink>
        </li>
        <li className="mr-9">
            <NavLink to="/about" className={`text-gray-200 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 ${location.pathname === '/about' ? 'bg-gray-700' : ''}`}> 
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                About
            </NavLink>
        </li>
        <li className="mr-9">
            <NavLink to="/resume" className={`text-gray-200 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 ${location.pathname === '/resume' ? 'bg-gray-700' : ''}`}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                Resume
            </NavLink>
        </li>
        <li className="mr-9">
            <NavLink to="/contact" className={`text-gray-200 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 ${location.pathname === '/contact' ? 'bg-gray-700' : ''}`}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                Contact
            </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;





/*import React from 'react';
import { Link } from 'react-router-dom';

function Menu() {
  return (
    <nav className="bg-gray-800 text-white p-4">
      <ul className="flex">
        <li className="mr-6">
          <Link to="/" className="hover:text-gray-300">Home</Link>
        </li>
        <li className="mr-6">
          <Link to="/about" className="hover:text-gray-300">About</Link>
        </li>
        <li className="mr-6">
          <Link to="/portfolio" className="hover:text-gray-300">Portfolio</Link>
        </li>
        <li className="mr-6">
          <Link to="/resume" className="hover:text-gray-300">Resume</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;*/
