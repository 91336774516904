import React from 'react';

function Home() {
  return (
    <div className="flex justify-center items-center h-screen">
      {/* Presentation Section */}
      <div className="w-1/2 py-16 px-8 text-white">
        <div className="max-w-md mx-auto">
          <h3 className="text-2xl mb-3 text-[#54D3D4]">Welcome, my name is</h3>
          <h1 className='text-6xl font-bold text-[#C5D1ED]'>JOSE LUCENA</h1>
          <p className="text-lg text-[#C4D1EC] mt-3">I'm a Software Engineer specialized in Web Development.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
