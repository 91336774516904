import React from 'react';

function About() {
  return (
    <section id="about" className="flex justify-center items-center h-screen">
      {/* Presentation Section */}
      <div className="w-1/2 py-16 px-8 text-white">
        <div className="max-w-md mx-auto">
          <h3 className="text-3xl mb-2 text-[#54D3D4]">About Me</h3>
          <p className="text-lg text-[#C4D1EC] mt-3">
            I’m a <b>Senior Software Engineer</b> with a deep specialization in web application development, particularly in PHP. My career has been defined by my ability to design and develop complex systems that are both efficient and scalable.
          </p>
          <br/>
          <p className="text-lg text-[#C4D1EC] mt-3">
            In addition to my technical skills, I have significant experience as a <b>Team Lead</b>. I guide and support development teams, ensuring projects are executed efficiently while fostering a collaborative environment.
          </p>      
        </div>
      </div>
    </section>
  );
}

export default About;
