import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-10 text-center md:justify-between">
      <div className="flex justify-between items-center container mx-auto text-white px-10">
        {/* Attribution */}
        <div className="text-sm">
          <p>Designed and Developed by Jose Lucena</p>
        </div>

        {/* Copyright */}
        <div className="text-center">
          <p>Copyright &copy; {year} <span className="text-bold text-xs">LM</span></p>
        </div>

        {/* Social Media Links */}
        <div className="text-right">
          <a href="https://www.linkedin.com/in/joselucena/" target='_blank' className="text-white hover:text-gray-400 mx-2"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://www.instagram.com/joselucena_rt/" target='_blank' className="text-white hover:text-gray-400 mx-2"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
